import { navigate } from 'gatsby'
import React from 'react'

import Head from 'components/Head'
import paths from 'constants/paths'
import Utility from 'templates/Utility'

export default class NotFoundError extends React.Component {
  state = {}

  componentDidMount() {
    const { pathname } = this.props.location

    const latestSlug = '/latest/'

    if (pathname.includes(latestSlug)) {
      navigate(pathname.replace(latestSlug, ''))
    } else {
      this.setState({ failed: true })
    }
  }

  render() {
    const { failed } = this.state

    return !failed ? null : (
      <>
        {/* TODO: Utility has `Head` that overrides… */}
        <Head title="Not found" />
        <Utility
          title="Darn, we couldn’t find what you’re looking for"
          button="Go to home"
          buttonTo={paths.HOME}
          className="NotFoundError">
          Sorry for the inconvenience.
        </Utility>
      </>
    )
  }
}
